<!--
 ~ Copyright 2024 the original author or authors.
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 ~ in compliance with the License. You may obtain a copy of the License at
 ~
 ~ https://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software distributed under the License
 ~ is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
 ~ or implied. See the License for the specific language governing permissions and limitations under
 ~ the License.
 ~ 
 ~ @author Jared Hatfield (UnitVectorY Labs)
-->
<template>
  <div class="container-fluid bg-light">
    <footer class="border-top py-3">
      <p class="text-center text-body-secondary mb-2">Powered By:</p>
      <div class="d-flex justify-content-center">
        <a href="https://github.com/UnitVectorY-Labs/ServiceAuthCentral" class="nav-link px-2 text-body-secondary" target="_blank">
          <i class="bi bi-github"></i> ServiceAuthCentral
        </a>
        <a href="https://github.com/UnitVectorY-Labs/serviceauthcentralweb" class="nav-link px-2 text-body-secondary" target="_blank">
          <i class="bi bi-github"></i> serviceauthcentralweb
        </a>
      </div>
      <p class="text-center text-body-secondary fw-bold text-dark mt-2">UnitVectorY Labs</p>
    </footer>
  </div>
</template>
